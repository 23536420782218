<template>
  <v-list-item-group>
    <template v-if="!isAuthenticated">
      <LanguageMenuItem />
      <v-list-item active-class="primary" :to="toLogin">
        <v-list-item-icon>
          <v-icon>mdi-login</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ $t("loginOrRegisterItem") }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>
    <template v-else>
      <template v-for="({ title, icon, to, showItem }, i) in items">
        <v-list-item active-class="primary" :to="to" v-if="showItem" :key="i">
          <v-list-item-icon>
            <v-icon>{{ icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
      <LanguageMenuItem />
      <LogoutItem />
    </template>
  </v-list-item-group>
</template>

<script>
import { i18n } from "@/plugins/i18n";
import { authRoutesName, userRoutesName } from "@/router/names";
import { isAuthenticated, getIsKioskSetting } from "@/helpers";

export default {
  name: "ListItemGroupUser",
  components: {
    LanguageMenuItem: () => import("./LanguageMenuItem.vue"),
    LogoutItem: () => import("./LogoutItem.vue"),
  },
  data() {
    return {
      items: [
        {
          title: i18n.t("orders"),
          icon: "mdi-order-bool-ascending-variant",
          to: { name: "OrdersIndex" },
          showItem: !getIsKioskSetting,
        },
        {
          title: i18n.t("myAccountItem"),
          icon: "mdi-account",
          to: { name: userRoutesName.INDEX.name },
          showItem: !getIsKioskSetting,
        },
      ],
    };
  },
  computed: {
    isKiosk() {
      return getIsKioskSetting;
    },
    isAuthenticated() {
      return isAuthenticated();
    },
    toLogin: () => ({ name: authRoutesName.INDEX.name }),
  },
};
</script>
